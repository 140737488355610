import Heading from "@/components/Heading";
import Link from "@/components/Link";
import SpacePage from "@/components/SpacePage";
import SpaceSection from "@/components/SpaceSection";
import {
  Box,
  Flex,
  Image,
  HStack,
  Text,
  VStack,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { PageProps } from "gatsby";
import React, { ReactElement, ReactNode } from "react";

interface DdProps {
  className?: string;
  children: ReactNode;
}

interface DtProps extends DdProps {
  bullet: string;
}

function Dt(props: DtProps) {
  const { className, bullet, children } = props;
  return (
    <Flex
      as="dt"
      pt="6"
      pb="2"
      fontSize="xl"
      fontFamily="heading"
      className={className}
    >
      <Box flexGrow={0} w="3ex">
        {bullet}
      </Box>
      <Box flexGrow={1}>{children}</Box>
    </Flex>
  );
}

function Dd(props: DdProps) {
  const { className, children } = props;
  return (
    <Box as="dd" pl="3ex" fontSize="xl" className={className}>
      <Box fontSize="md">{children}</Box>
    </Box>
  );
}

const googleMapsUrl = [
  `https://www.google.com/maps/place/`,
  `30+Cheapside,+Brighton+BN1+4GD,+UK/`,
  `@50.8293337,-0.1406608,17z/`,
  `data=!3m1!4b1!4m5!3m4!1s0x4875859e03800c0b:0x9e1dc66912fdd215!8m2!3d50.8293337!4d-0.1384721?hl=en`,
].join("");

const openStreetMapUrl =
  "https://www.openstreetmap.org/#map=18/50.82932/-0.13912";

function ContactUs(): ReactElement {
  return (
    <SpaceSection bg="white" ct="lg" pt="24">
      <Heading level="section" size="lg">
        Contact Us
      </Heading>

      <SimpleGrid columns={[1, 2]}>
        <Box as="dl">
          <Dt bullet="📬">Email</Dt>
          <Dd>
            <Link to="mailto:hello@spacecat.io">hello@spacecat.io</Link>
          </Dd>
          <Dt bullet="🐦">Twitter</Dt>
          <Dd>
            <Link target="_blank" to="https://twitter.com/spacecatio">
              @spacecatio
            </Link>
          </Dd>
          <Dt bullet="☎️">Telephone</Dt>
          <Dd>
            From the UK: <Link to="tel:442045386369">0204 538 6369</Link>
            <br />
            International: <Link to="tel:442030955339">+44 204 538 6369</Link>
          </Dd>
        </Box>
        <Box as="dl">
          <Dt bullet="🏡">Address</Dt>
          <Dd className="vcard">
            <div className="adr">
              <div className="org fn">
                <p className="organization-name">Spacecat</p>
                <p className="extended-address">The Skiff</p>
                <p className="street-address">30 Cheapside</p>
                <p>
                  <span className="region">Brighton</span>{" "}
                  <span className="postal-code">BN1 4GD</span>
                </p>
                <p className="country-name">UK</p>
              </div>
            </div>
          </Dd>
          <Dt bullet="🗺">Directions</Dt>
          <Dd>
            <Link target="_blank" to={googleMapsUrl}>
              Google Maps
            </Link>
            <br />
            <Link target="_blank" to={openStreetMapUrl}>
              OpenStreetMap
            </Link>
          </Dd>
        </Box>
      </SimpleGrid>
    </SpaceSection>
  );
}

function CompanyInfo(): ReactElement {
  return (
    <SpaceSection bg="white" pt="24" pb="36">
      <Heading level="section" size="lg">
        Company Info
      </Heading>

      <SimpleGrid columns={[1, 2]}>
        <Box as="dl">
          <Dt bullet="🐱">Registered Company Name</Dt>
          <Dd>Spacecat Ltd</Dd>
          <Dt bullet="🌍">Registered In</Dt>
          <Dd>England and Wales</Dd>
        </Box>
        <Box as="dl">
          <Dt bullet="🏛">Company Number</Dt>
          <Dd>13166582</Dd>
          <Dt bullet="💰">VAT Registration Number</Dt>
          <Dd>369 8853 23</Dd>
        </Box>
      </SimpleGrid>
    </SpaceSection>
  );
}

function AboutUs(): ReactElement {
  return (
    <SpaceSection bg="beige" pt={["8", "16"]} pb="16">
      <Box>
        <Heading level="page">About</Heading>

        <Text>
          Spacecat was founded by <strong>Dave and Nic Pereira-Gurnell</strong>:
        </Text>

        <Stack
          mt="8"
          direction={["column", "column", "row"]}
          spacing="8"
          alignItems="flex-start"
        >
          <VStack spacing="4" flexBasis={[undefined, undefined, "50%"]}>
            <Image
              display="block"
              src="/images/about/dave.jpg"
              w={["192px", "192px"]}
              borderRadius="full"
              alt="Dave Pereira-Gurnell"
            />
            <Text mt="4">
              Dave is a regular writer and speaker on functional programming. He
              has contributed to numerous books on Scala and has spoken at
              conferences such as Scala eXchange, Scala Days, Lambda World, and
              ICFP.
            </Text>
          </VStack>
          <VStack spacing="4" flexBasis={[undefined, undefined, "50%"]}>
            <Image
              display="block"
              src="/images/about/nic.jpg"
              w={["192px", "192px"]}
              borderRadius="full"
              alt="Nic Pereira-Gurnell"
            />
            <Text mt="4">
              Nic is all things events, team and project management. With close
              to 15 years experience in organising meetups, workshops and large
              scale conferences such as DroidCon and Scala eXchange, she&apos;s
              an expert in events production and chasing tech speakers like
              Dave. She&apos;s passionate about connecting communities and
              mentoring teams.
            </Text>
          </VStack>
        </Stack>
      </Box>
    </SpaceSection>
  );
}

export default function AboutPage(_props: PageProps): ReactElement {
  return (
    <SpacePage title="About" accent="purple" activeMenuItem="about">
      <AboutUs />
      <ContactUs />
      <CompanyInfo />
    </SpacePage>
  );
}
